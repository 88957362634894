import { httpGet } from 'utils';
import { OfferTypeEnum, OffersType } from '../types';

interface IFetchOtherPortfolioOffersResponse {
  data: OffersType[];
  count?: number;
}

interface IFetchOtherPortfolioOffersParam {
  page?: number;
  pageSize?: number;
  searchText?: string;
}

export const fetchOffers = (params?: IFetchOtherPortfolioOffersParam, signal?: AbortSignal | undefined) => {
  const path = `portal/Orders?offerType=${
    OfferTypeEnum.MARKET_OFFERS
  }&buyOrSell=S&orderType=Regular,Cwi&inPackage=false&pageSize=${params?.pageSize}&page=${params?.page}
  ${params?.searchText ? `&queryStr=${params.searchText}` : ''}`;

  return httpGet<IFetchOtherPortfolioOffersResponse>(path, undefined, true, signal);
};
